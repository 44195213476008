import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { URL } from '@/types';

export default function RequireLinkAWSMarketplace() {
  // local storageのSaaSusAWSMarketplaceRegistrationTokenをチェックする
  const saaSusAWSMarketplaceRegistrationToken =
    localStorage.getItem('SaaSusAWSMarketplaceRegistrationToken') ?? '';

  return saaSusAWSMarketplaceRegistrationToken !== '' ? (
    <Outlet />
  ) : (
    <Navigate to={URL.SIGN_UP_COMPLETION} />
  );
}
