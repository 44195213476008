import { Box, Button, CircularProgress, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useToast } from '@/components/ToastProvider';
import useAccount from '@/hooks/useAccount';
import { fetchUserInfo } from '@/services/authSlice';
import { createUser, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, RegisterForm, URL } from '@/types';

export default function SignUpConfirmation() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { toast } = useToast();
  const { defaultValues } = useAccount();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { status, error } = useAppSelector(userSelector);
  const [user, setUser] = useState<RegisterForm>(defaultValues);
  const [loading, setLoading] = useState(false);
  const LOGIN_URL = process.env.REACT_APP_SAASUS_URL ?? '';

  useEffect(() => {
    if (state) {
      setUser({
        ...user,
        tenant_name: state.tenant_name,
        connect_user_name: state.connect_user_name,
        connect_instance_id: state.connect_instance_id,
        aws_account_id: state.aws_account_id,
        saml_url: state.saml_url,
        iam_role_arn_for_cdk: state.iam_role_arn_for_cdk,
      });
    }
  }, [state]);

  useEffect(() => {
    if (status.create === AsyncStatus.SUCCESS) {
      toast({ message: t('pages:sign_up:messages:受付が完了しました。') });

      dispatch(fetchUserInfo()).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          navigate('/');
        } else {
          window.location.href = LOGIN_URL;
        }
      });
    }
    if (status.create === AsyncStatus.FAILED) {
      // toast({ message: t('pages:sign_up:messages:register_error'), type: 'error' });
      toast({ message: 'error:' + error, type: 'error' });
      setLoading(false);
    }
  }, [status]);

  const onSubmitForm = () => {
    setLoading(true);
    dispatch(createUser(user));
  };

  const onClickCancel = () => {
    navigate(URL.SIGN_UP_REGISTRATION, { state });
  };

  return state ? (
    <Container maxWidth="md" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:sign_up:title')}
      </Typography>
      <Box sx={{ mb: 3 }}>以下の内容でお間違いなければ登録ボタンをクリックしてください。</Box>
      <Stack component="form" noValidate spacing={2} sx={{ mb: 6 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>{t('pages:sign_up:labels:email')}</Typography>
          <Typography>{state.email}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:tenant_name')}
          </Typography>
          <Typography>{state.tenant_name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:connect_user_name')}
          </Typography>
          <Typography>{state.connect_user_name}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:connect_instance_id')}
          </Typography>
          <Typography>{state.connect_instance_id}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:aws_account_id')}
          </Typography>
          <Typography>{state.aws_account_id}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:saml_url')}
          </Typography>
          <Typography>{state.saml_url}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ mb: 1, color: 'grey' }}>
            {t('pages:sign_up:labels:iam_role_arn_for_cdk')}
          </Typography>
          <Typography>{state.iam_role_arn_for_cdk}</Typography>
        </Box>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <Button type="button" onClick={onClickCancel}>
          {t('pages:sign_up:buttons:back')}
        </Button>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Button type="button" color="primary" variant="contained" onClick={onSubmitForm}>
            {t('pages:sign_up:buttons:register')}
          </Button>
        )}
      </Box>
    </Container>
  ) : (
    <Navigate to={URL.SIGN_UP_REGISTRATION} />
  );
}
