export * from './account';

export type Nullable<T> = T | null | undefined;

export enum AsyncStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum URL {
  CALLBACK = '/callback',
  SIGN_UP_REGISTRATION = '/account/registration',
  SIGN_UP_CONFIRMATION = '/account/confirmation',
  SIGN_UP_COMPLETION = '/account/Completion',
  LINK_AWS_MARKETPLACE = '/account/LinkAWSMarketplace',
}

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};
