import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect, useState } from 'react';

import { useToast } from '@/components/ToastProvider';
import { authSelector } from '@/services/authSlice';
import { useAppSelector } from '@/store/hooks';

dayjs.extend(isBetween);
import 'dayjs/locale/ja';

import {
  Button,
  CircularProgress,
  Container,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function LinkAWSMarketplace() {
  const { t } = useTranslation();
  const { account } = useAppSelector(authSelector);
  const { toast } = useToast();

  const [userAvailableTenants, setUserAvailableTenants] = useState<any[]>([]);
  const [selectedTentantId, setSelectedTenantId] = useState<string>('');

  useEffect(() => {
    console.log(account);
    setUserAvailableTenants(account?.tenants);
  }, [account?.tenants]);

  const link = async () => {
    console.log(selectedTentantId);
    if (selectedTentantId == '') {
      toast({ message: t('pages:link_aws_marketplace:messages:select_tenant'), type: 'error' });
      return;
    }
    const response = await axios.post(process.env.REACT_APP_LINK_AWS_MARKETPLACE!, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      access_token: localStorage.getItem('SaaSusAccessToken') as string,
      tenant_id: selectedTentantId,
      registration_token: localStorage.getItem('SaaSusAWSMarketplaceRegistrationToken') as string,
    });
    if (response.data.isSuccess) {
      toast({ message: t('pages:link_aws_marketplace:messages:completed') });
    } else {
      toast({ message: response.data.message, type: 'error' });
    }
  };

  return account.tenants == undefined ? (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <CircularProgress />
    </Container>
  ) : (
    <Container maxWidth="sm" sx={{ py: [6, 10] }}>
      <Typography variant="h5" textAlign="center" sx={{ fontWeight: 'bold', mb: 6 }}>
        {t('pages:link_aws_marketplace:title')}
      </Typography>

      <Stack component="form" noValidate spacing={3} sx={{ mb: 6 }}>
        <label>{t('pages:link_aws_marketplace:messages:select_sylphina_tenant')}</label>

        <Select
          value={selectedTentantId}
          onChange={(e: any) => setSelectedTenantId(e.target.value)}
        >
          {userAvailableTenants.map((t) => {
            return (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            );
          })}
        </Select>

        <Button
          color="primary"
          fullWidth
          size="large"
          variant="contained"
          type="button"
          onClick={link}
        >
          {t('pages:link_aws_marketplace:messages:submit_button')}
        </Button>
      </Stack>
    </Container>
  );
}
